import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

export function getFacilities(query) {
    let queryString = qs.stringify(query);
    return axios.get('/facilities?' + queryString);
}
export function importCsv(data, options) {
    let formData = new FormData();
    formData.append('files.files', data);
    formData.append('data', JSON.stringify({ strapi: true }));
    return axios.post('/facilities/import-csv', formData, options);
}

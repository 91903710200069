<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
                    <v-container>
                        <v-file-input accept="text/csv" :label="$t('SelectFile')" v-model="file"></v-file-input>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('ImportFacilities') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('ImportFacilities') }}
                        </p>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A IMPORTAR INSTALAÇÕES SANITÁRIAS-->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ImportFacilitiesSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
        mdiUpload,
        mdiFileImportOutline,
    } from '@mdi/js';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { importCsv } from '@/api/facility';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            return {
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                dialog: false,
                isLoading: false,
                file: null,
            };
        },
        setup() {
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                mdiUpload,
                mdiFileImportOutline,
            };
        },
        methods: {
            async onSubmit() {
                try {
                    this.isLoading = true;
                    await importCsv(this.file, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    this.createSuccess = true;
                    setTimeout(() => {
                        this.$router.push({ name: 'FacilityList' });
                    }, 1500);
                } catch (error) {
                    this.isError = true;
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>
<style lang="scss" scoped></style>
